import React from 'react';

const MensionsLegales = () => {
    return (
        <div className="container-ml">
            <h2 className="ml">Mention Légales</h2>
            <p>En naviguant sur www.la-belle-de-ronce.com, ci-après « le Site », vous reconnaissez, en votre qualité d'utilisateur, ci-après désigné par « vous » ou « l’Internaute », connaître les conditions d'usage figurant ci-après, ainsi que les conditions spécifiques relatives à certains services du Site, et en accepter les termes.</p>
            <span className="lign">Adresse</span>
            <p>La Belle de Ronce : Prise de l’Euse 17390- La Tremblade</p>

            <span className="lign">Email</span>
            <p>la.belle.de.ronce@gmail.com<br/>OU<br/>gerard.vollet@wanadoo.fr</p>

            <span className="lign">Information légales</span>
        <br/>
        <b>Propriétaires associés :</b>
        <p>David Bouron & Guy Moyer</p>
<p>Immatriculée au registre du commerce et des sociétés de La Rochelle (17000 – France) sous le nom GERARD-VOLLET LA BELLE DE RONCE et le numéro 844 335 588 LA ROCHELLE.
SIRET : 844 335 588 00019
</p>
        
<b>Réalisation et implantation web :</b>
<p>Dorine Giustina</p>
<b>Montage Video :</b>
<p>Jordane Lepart</p>
<b>Photographie :</b>
<p>David Bouron & Guy Moyer</p>

 
<b>Hébergement du site :</b>

<p>1&1 Internet SARL 1&1 IONOS Cloud GmbH<br/>

SARL au capital de 100 000 EUR GmbH au capital de 5 814 171 EUR<br/>

RCS Sarreguemines B 431 303 775 RCS Charlottenburg HRB 125506 B<br/>

SIRET 431 303 775 000 16 SIRET 29 026 63822<br/>

Code APE: 6201Z<br/>

Identification intracommunautaire FR 13 431303775</p>


<b>Propriétaire / éditeur du site :</b>

<p>SARL Gérard-Vollet-La Belle de Ronce</p>
<b>Exonération de responsabilité du contenu :</b>
<p>
Certains  liens hypertextes de ce site Internet pointent en direction d’autres sites ou ressources présentes sur le Web. Les contenus de ces derniers ne sauraient engager la responsabilité de « La Belle de Ronce ».
</p>
<span className="lign">Droits de propriété intellectuelle</span>
<p>Le Site et l’ensemble de son contenu (en particulier les marques commerciales, logos, tout élément graphique, textuel et informatique du Site dont notamment les arborescences, mises en forme, articles, présentations, illustrations, photographies, et/ou tout autre document) sont protégés par les règlementations françaises et internationales relatives à la propriété intellectuelle.

En aucun cas, la navigation sur le Site, ou les mentions y figurant, ne peuvent être interprétées comme accordant une licence d'utilisation ou un quelconque droit d'utiliser ces éléments. A ce titre, leurs représentations, reproductions, imbrications, diffusions et rediffusions, partielles ou totales, sont interdites (article L. 122-4 du Code de la propriété intellectuelle français). Toute personne y procédant sans pouvoir justifier d'une autorisation préalable et expresse du détenteur de ces droits encourt les peines relatives au délit de contrefaçon (articles L. 335-2 et suivants du Code de la propriété intellectuelle français). L’Editeur pourra se prévaloir de ses droits, en particulier de propriété intellectuelle, pour engager des poursuites notamment pénales.

L’Editeur se réserve le droit d'améliorer ou de modifier à tout moment les informations, produits, programmes ou conditions d’utilisation décrits dans le Site, et ce sans que sa responsabilité puisse être engagée de ce fait. Les conditions et mentions applicables seront celles en vigueur à la date de votre connexion au Site.

Les photographies utilisées sur le Site sont protégées par le droit d’auteur. Pour des raisons pratiques, les crédits ne sont pas mentionnés sur la photographie. Pour plus d’information sur l’auteur des photographies, vous pouvez contacter l’Editeur via le formulaire de contact figurant sur le Site.</p>


<span className="lign">Liens hypertextes</span>
<p>Le Site peut comporter des liens hypertextes vers des sites tiers. L'Internaute qui serait redirigé vers un site tiers par l'intermédiaire d'un lien hypertexte établi en direction d’autres sites reconnaît que l’Editeur ne maîtrise pas le contenu, les informations, les produits et services offerts sur ces sites. En conséquence, l’Editeur ne pourra en aucun cas voir sa responsabilité engagée pour d’éventuels dommages directs ou indirects résultant de l'utilisation de sites accessibles via les liens hypertextes.
L’Editeur n’est également pas responsable des liens hypertextes pointant vers le Site.

Toute personne souhaitant mettre en place des liens hypertextes pointant vers le Site devra en obtenir l’autorisation écrite et préalable de l’Editeur.</p>


<span className="lign">Limitation de responsabilité</span>
<p>L’Editeur rappelle aux Internautes les caractéristiques et les limites du réseau internet. L’Editeur ne saurait être tenu responsable de tout dommage résultant de l’accès au Site ou, au contraire, de l’impossibilité d’y accéder. L’Editeur ne pourra en aucun cas être tenu responsable en cas d’indisponibilité du Site, pour quelque cause que ce soit (défaut technique, encombrement du réseau…).

L'Internaute admet expressément utiliser le Site à ses propres risques et sous sa responsabilité exclusive. L’Editeur ne saurait être tenu responsable de tout dommage, direct ou indirect, matériel ou immatériel causé aux Internautes, à leurs équipements informatiques ou de télécommunications, aux données qui y sont stockées et aux conséquences pouvant en découler sur leur activité personnelle, professionnelle ou commerciale.

Les informations du Site sont fournies à titre indicatif. Les informations diffusées sur le Site sont actualisées et vérifiées périodiquement mais peuvent encore contenir des erreurs. Les informations doivent en tout état de cause être prises en considération au moment de leur mise en ligne et non au moment de la consultation du Site (ces données peuvent avoir fait l’objet de mises à jour entre le moment où vous les avez téléchargées et celui où vous en prenez connaissance). En tout état de cause, la responsabilité de l’Editeur ne saurait être engagée du fait de ces informations.</p>


<span className="lign">Utilisation du site</span>
<p>L’Editeur propose le Site principalement pour l’usage de sa clientèle professionnelle, ses distributeurs, les particuliers et de toute personne souhaitant connaître ses produits et services.
<br/>
Lorsque vous naviguez sur le Site :
<br/>
- Vous vous engagez à respecter les présentes mentions ainsi que, plus généralement, les législations applicables,
<br/>
- Vous vous abstenez de toute collecte, utilisation détournée notamment des informations nominatives auxquelles vous pourriez accéder et, de manière générale, de tout acte susceptible de porter atteinte aux tiers.
<br/>
Lorsque vous remplissez un formulaire et que vous fournissez des informations, vous vous engagez à :
<br/>
- Délivrer des informations réelles, exactes, à jour au moment de leur saisie et notamment à ne pas utiliser de faux noms, qualités ou adresses, ou encore des noms, qualités ou adresses sans y être autorisé,
<br/>
- Ne pas rendre disponible ou distribuer des informations, des programmes ou des éléments illégaux, répréhensibles ou encore nuisibles (tels que des virus, des logiciels de piratage ou de copie),
<br/>
- Ne pas diffuser des propos, opinions ou informations à caractère diffamatoire, obscène, violent, raciste et plus généralement contrevenant aux lois et règlements en vigueur, aux droits des personnes et aux bonnes mœurs.
<br/>
En cas de violation de ces dispositions, l’Editeur se réserve le droit de vous interdire l'accès au Site ou aux services proposés, à vos torts exclusifs et ce, sans préjudice de toute action en responsabilité.</p>
<h2 className="ml">Politique de cookie</h2>
<p>Lors de votre première connexion sur notre site web, ci-après le Site, vous êtes informé par un bandeau d’information, que certaines données relatives à votre navigation sont susceptibles d’être traitées via des cookies, ci-après Cookies, et vous êtes informés des modalités de recueil de votre consentement à cet effet. La présente Politique de Cookies précise les différentes finalités liées à ces Cookies et explique votre démarche à suivre pour les paramétres.</p>

<span className="lign">Les différents Cookies présents sur le Site</span>

<b>1. Les Cookies nécessaires au fonctionnement du Site</b>

<p>Ces Cookies sont nécessaires au bon fonctionnement du Site et à sa navigation. Ils vous permettent d’accéder aux principales fonctionnalités du Site.

Conformément aux recommandations de la CNIL, ces Cookies sont dispensés du recueil préalable de votre consentement. Afin de vous assurer le bon fonctionnement du Site, ces cookies ne peuvent pas être désactivés.

La Société Établissements La Belle de Ronce utilise sur son Site le Cookie suivant :

Le Cookie « tarteaucitron» est apposé par un script affichant une bannière permettant à l’utilisateur l’acceptation des Cookies au cas par cas et est conservé 1 an, afin de déterminer pour quels Cookies l’utilisateur a donné son consentement.</p>
<b>2. Les Cookies tiers de mesure d’audience</b>
<p>Ces Cookies sont utilisés, nécessairement avec votre accord, afin d’évaluer l’utilisation du Site, en collectant des informations sur votre navigation. Ces informations sont collectées afin d’effectuer des mesures d’audience, de statistiques, dans le but d’améliorer la qualité des services fournis par le Site.

La Société Etablissements La Belle de Ronce utilise Google Analytics pour collecter les informations sur l’origine du trafic, l’activité sur la page et le temps de présence sur le Site.

Pour plus d’information sur ces Cookies, nous vous invitons à prendre connaissance de leurs conditions d’utilisation au lien suivant : https://support.google.com/analytics/answer/7108387?hl=fr

En outre via votre navigateur, il vous est possible de gérer et de désactiver ces Cookies directement via le lien suivant : https://tools.google.com/dlpage/gaoptout</p>


<span className="lign">Comment paramétrer les Cookies ?</span> 
<p>Votre navigateur vous donne la possibilité de modifier ses paramètres standards, mais également de supprimer les Cookies existants et déposés sur votre équipement terminal, ou de vous signaler si de nouveaux Cookies sont susceptibles d’être déposés sur votre terminal.

Toutefois, si vous choisissez de désactiver les Cookies via votre navigateur, il est possible que vous ne puissiez plus bénéficier de toutes les fonctionnalités que nous proposons à travers le Site.
<br/>
Pour exprimer ou revenir sur vos choix, reportez-vous au menu d’aide ou à la rubrique dédiée de votre navigateur. À titre d’exemple vous pouvez obtenir plus d’information sur les pages suivantes :</p>
<br/>
<p>
Pour Internet Explorer™: http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies
<br/>
Pour Safari™: http://support.apple.com/kb/ht1677?viewlocale=fr_FR
<br/>
Pour Chrome™: https://support.google.com/chrome/answer/95647?hl=fr
<br/>
Pour Firefox™ : https://support.mozilla.org/fr/kb/activer-desactiver-cookies
<br/>
Pour Opera™ : http://help.opera.com/Windows/10.20/fr/cookies.html
<br/>
Pour plus d’informations sur les Cookies et vos droits, vous pouvez aussi consulter le site internet de la CNIL.</p>
        </div>
    );
};

export default MensionsLegales;