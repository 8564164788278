import React from 'react';
import  {Link}  from 'react-router-dom';
const Footer = () => {
    return (  
        <footer className="main-footer">
            <Link to ="/mentions-legales">
                    <h4>Mentions Légales</h4>
            </Link>
                <a href="https://www.facebook.com/labellederonceMB" target="_blank" rel="noopener noreferrer" className="hover"><i id="menu" className="fab fa-facebook-f"></i></a>
            <Link to ="/contact">
                    <h4>Contact</h4> 
            </Link>
            <Link to ="/mentions-legales" >
             <span className="span">Mentions Légales<br/><br/>Politique de confidentialité</span>
            </Link>
            <span>© Établissement La Belle de Ronce - 2021<br/>Réalisation site internet : Giustina Dorine</span>
            <span className="span">
            Email : la.belle.de.ronce@gmail.com
            <br/><br/>
            Adresse : Prise de L'Euse - 17830 La Tremblade
            <br/><br/>
            Téléphone : 05 46 36 03 80
            </span>
        </footer>
    );
};

export default Footer;