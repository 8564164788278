import React, { useEffect } from 'react';
import img1 from './../img/cabane-hist.jpg';
import img2 from './../img/creux-2.jpg';
// import img3 from './../img/creux-2.jpg';
// import img4 from './../img/creux-2.jpg';
// import img5 from './../img/creux-2.jpg';
// import img6 from './../img/creux-2.jpg';
import img7 from './../img/logo-argent.png';
import Aos from "aos";
const Histoire = () => {
    useEffect(()=>{
        Aos.init({duration: 2000});
    },[]);
  
    return (
        <div className="histoire">
            <figure className="paralaxe-hist"></figure>
            <div className="container-hist">
                <h1 className="notre-hist">Notre Histoire</h1> 
            </div>
            <span className="intro">La complémentarité étant une force dans ce métier,<b className="noms"> l’EARL Gérard VOLLET</b> a choisi de céder la
direction de l’établissement familial en 2019 à deux associés, deux passionnés plongés dans les
claires depuis leur entrée dans la vie active, deux professionnels du secteur ostréicole : <br/> <br/></span>
<span className="intro"><b className="noms">David BOURON</b> ayant travaillé au sein de l'établissement depuis de nombreuses années,
<br/>
Et
<br/>
<b className="noms">Guy MOYER</b> ayant déjà été responsable pendant 10 ans d’un établissement ostréicole très reconnu
dans la profession.
<br/>
</span>
<p className="intro">
<br/>
Ensemble, ils font évoluer l’établissement.
Ils choisissent un nom évocateur de la noblesse de l’Huître élevée ici, à Marennes Oléron, depuis des
décennies :
<br/>
<br/><br/>
</p>
<p className="BDR">La Belle de Ronce</p>
<p className="intro">
<br/>
Cette <b className="noms">BELLE DE RONCE</b> est issue d’un environnement naturel protégé exceptionnel.
La gamme d’huîtres que nous vous proposons est aussi née de savoir-faire transmis et de compétences
acquises au fil du temps ; mais aussi d’un travail quotidien par « vents et marées » de toute une équipe
investie.
</p>
<p className="intro">
<br/>
Toutes les conditions sont réunies pour vous proposer des huîtres d’une haute qualité, respectant
l’affiliation de notre établissement à l’appellation<br/> <b className="noms">IGP et Label Rouge MARENNES OLERON</b>.
<br/>
C’est en respectant le rythme des saisons que nous pouvons vous proposer des huîtres adaptées à vos
papilles toute l’année.</p>
            <div className="entries">
                <div className="entry" data-aos="fade-right">
                    <div className="title">1990</div>
                    <div className="body">
                    <img className="img-left" src={img1} title="" alt=""/>
                        <p className='p'>Création de la société EARL Gérard VOLLET<br/>Construction du bâtiment de 550m² pour gagner en efficacité, en rendement et en
conditions de travail et acquisition du marais autour de 9ha.</p>    
                    </div>
                </div>
                <div className="entry" data-aos="fade-left">
                    <div className="title">2019</div>
                    <div className="body">
                    <img className="img-right" src={img2} title="" alt=""/>
                        <p className='p2'>Reprise de l’exploitation Gérard Vollet par Guy Moyer et David Bouron.</p>
                    </div>
                </div>
                <div className="entry" data-aos="fade-right">
                    <div className="title">2021</div>
                    <div className="body">
                    <img className="img-left" src={img7} title="" alt=""/>
                    <p className='p3'>Lancement de notre application web : la-belle-de-ronce.com. Ainsi que la conception d'un logo propre à l'image de l'entreprise et notre savoir faire.</p>    
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Histoire;