import React, { useEffect } from 'react';
import SocialNetwork from '../components/SocialNetwork';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContactForm from '../components/ContactForm';
import img from './../img/cabane-con.jpg';
import Aos from "aos";
const Contact = () => {
    useEffect(()=>{
        Aos.init({duration: 2000});
    },[]);
    return (
        <div className="container">
            <figure className="paralaxe"></figure>
            <div className="container-form">
                <ContactForm/>
                <div className="img-contact">
                    <img data-aos="fade-right" src={img} alt="logo-la-belle-de-ronce" title="La Belle de Ronce"/>
                </div>
                <div className="contact" data-aos="fade-left">
                <div className="contact-infos">
                    <div className="adress">
                        <div className="content">
                            <h4>Adresse</h4>
                            <CopyToClipboard text="Prise de L'Euse 17830 La Tremblade" className="hover">
                            <p style={{ cursor: 'pointer' }}
                                    className="clipboard"
                                    onClick={() => {
                                        alert('Adresse Copié !')
                                    }}>Prise de L'Euse
                            <br/>17830 La Tremblade</p>
                            </CopyToClipboard>
                        </div>
                    </div>

                    <div className="phone">
                        <div className="content">
                            <h4>Téléphone</h4>
                            <CopyToClipboard text="0546360380" className="hover">
                                <p style={{ cursor: 'pointer' }}
                                    className="clipboard"
                                    onClick={() => {
                                        alert('Téléphone Copié !')
                                    }}>05.46.36.03.80</p>
                            </CopyToClipboard>
                        </div>
                    </div>
                    <div className="email">
                        <div className="content">
                            <h4>Email</h4>
                            <CopyToClipboard text="la.belle.de.ronce@gmail.com" className="hover">
                                <p style={{ cursor: 'pointer' }}
                                    className="clipboard"
                                    onClick={() => {
                                        alert('Email Copié !')
                                    }}>la.belle.de.ronce@gmail.com
                                    </p>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="ici">
            <h3>Nous sommes ici !</h3>
        </div>
        <div className="responsive-map-container">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d347.91687317536724!2d-1.1325792073523848!3d45.764476461320875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x480165fcdd0ae4df%3A0xb8d8bb49d6a1bdb1!2sLa%20Belle%20de%20Ronce!5e0!3m2!1sfr!2sfr!4v1618648462755!5m2!1sfr!2sfr" 
            width="100%" 
            title="localisation La belle de ronce"
            height="450" 
            loading="lazy"/>
              <SocialNetwork/>
        </div>
    </div>
      
    );
};

export default Contact;