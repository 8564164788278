import React, { useEffect } from 'react';
//import img2 from './../img/huitres.JPG';
import Img from './../img/Fine-de-claire-cote.jpg';
import Img2 from './../img/Fine-de-claire-verte.jpg';
import Img3 from './../img/Speciale-ouverte.jpg';
import Img4 from './../img/Pousse.jpg';
import Label1 from './../img/MO.png';

const Cards = () => {
 const data = [
    {
        "id":5,
        "title": "La Fine de Claire",
      "titleBack": "Fine de Claire",
      "img":Img,
      "link":"https://www.huitresmarennesoleron.info/fine_de_claire.html",
      "description": "Affinées dans les claires de Marennes Oléron. Un séjour de plusieurs semaines en claires leur confère un goût de « merroir » bien affirmé. Pour les amateurs d’huîtres peu charnues.",
      "labelTitle":"Labelisée",
      },
    {
      "id":2,
      "title": "La Fine de claire verte",
      "titleBack": "Fine de claire verte",
      "img": Img2,
      "link":"https://www.huitresmarennesoleron.info/fine_de_claire_verte.html",
      "description": "Elles répondent à des normes strictes de sélection, d’affinage et de verdissement en claires de Marennes Oléron. La Fine de claires Verte est typée « verte et charnue », sans laitance.",
      "labelTitle":"Labelisée",
    },
    {
      "id":3,
      "title": "La Spéciale de Claire",
      "titleBack": "Spéciale de Claire",
      "img":Img3,
      "link":`https://www.huitresmarennesoleron.info/speciale_de_claire.html`,
      "description": "Elles se distingue par la consistance plus affirmée de sa chair, son volume en bouche et un parfait équilibre de goût. Exceptionnelle pour les amateurs d’huîtres charnues.",
      "labelTitle":"Labelisée",
    },
    {
      "id":4,
      "title": "La Pousse en Claire",
      "titleBack": "Pousse en claire",
      "img":Img4,
      "link":"https://www.huitresmarennesoleron.info/pousse_en_claire.html",
      "description": "Élevage en claire de 4 mois minimum à raison de 2 à 5 huîtres/m². Croquante, de couleur ivoire, avec un goût de terroir prononcé, c’est la plus typée de Marennes Oléron.",
      "labelTitle":"Labelisée",
    },
    
]
useEffect(()=>{
    const card = document.querySelectorAll('.card__inner');
    for(let i = 0; i < card.length; i++){
    card[i].addEventListener('click', function(){
        card[i].classList.toggle('is-flipped');
    });}
},[]);
    return (
        <div className="on-the-top">
            <div className="content-cards">
            {data.map(post=>{
                return(
                    <>
                    <div className="card" key={post.id}>
              <div className="card__inner">
                  <div className='card__face card__face--front' >
                        <img className="h_img" src={post.img} alt={post.title} title={post.title}/>
                                <h3 className="title-huitre">{post.title}</h3>
                                {/* <i className="fa fa-plus"></i> */}
                            </div>
                        <div className='card__face card__face--back'>
                            <div className="card__content">
                                    <div className="labelise">
                                        <img className="img-label" src={Label1} alt={post.labelTitle} title={post.labelTitle}/>
                                    </div>
                                {/* <div className="card__header">
                                    <img className="huitres_img" src={img2} alt="" title=""/>
                                    <h3 className="sous_title"></h3>
                                </div> */}
                                <div className="card__body">
                                    <h5 className="des_title">{post.titleBack}</h5>
                                     <p className="des_huitres">{post.description}</p>
                                </div>
                             </div>
                             <a href={post.link} target="_blank" rel="noreferrer"><button className="button">En savoir plus</button></a>
                        </div>  
                        </div>
                    </div>
              
                 </>
                )
            })}
        </div>
    </div>
    );
};

export default Cards;