import  React from 'react';
import  {NavLink}  from 'react-router-dom';
import logo from './../img/logo-argent.png';
import logo2 from './../img/logo-argent.png';

const Navbar = () => {

//scroll du menu
window.addEventListener("scroll", function(){
    var header = document.querySelector("header");
    header.classList.toggle("sticky", window.scrollY > 2)

})
//rechargement en sticky
function refreshPage() {
    setTimeout(()=>{
        window.location.reload(true);
    }, 0.1);
}
    return (
        <header className="main-header">
             <NavLink to="/">  
                <div className="logo">
                     <img className="logo-white" src={logo} alt="logo-la-belle-de-ronce" title="La Belle de Ronce"/>   
                     <img className="logo-black" src={logo2} alt="logo-la-belle-de-ronce" title="La Belle de Ronce"/> 
                </div>
            </NavLink>
            <input type="checkbox" className="menu-btn" id="menu-btn"/>
            <label htmlFor="menu-btn" className="menu-icon">
                <span className="menu-icon__line"></span>
            </label>
            <ul className="nav-links">
                <li className="nav-link">
                    <NavLink to ="/notre-histoire" onClick={refreshPage}>
                        <h3>Présentation</h3>
                    </NavLink>
                </li>
                <li className="nav-link">
                    <NavLink to ="/huitres" onClick={refreshPage}>
                        <h3>Nos Huîtres</h3>
                    </NavLink>
                </li>
                <li className="nav-link">
                    <NavLink  to ="/contact" onClick={refreshPage}>
                        <h3>Contact</h3>
                    </NavLink>
                </li>
                <li className="nav-link">
                    <a href="https://www.facebook.com/labellederonceMB" target="_blank" rel="noopener noreferrer" className="hover"><i id="menu" className="fab fa-facebook-f"></i></a>
                </li>
                {/* <li className="nav-link">
                    <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="hover"><i id="menu" className="fab fa-instagram"></i></a>
                </li> */}
            </ul>
        </header>
    );
};

export default Navbar;