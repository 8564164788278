import {Route, Redirect, Switch} from 'react-router-dom';
import Contact from './pages/Contact';
import Huitres from './pages/Huitres';
import MentionsLegales from './pages/MentionsLegales';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Histoire from './pages/Histoire';
import Footer from './components/Footer';
import ScrollTop from './ScrollTop';

function App() {
  return (
    <>
    <ScrollTop/>
    <Navbar/>
    <Switch>
      <Route exact path="/"  component={Home}/>
      <Route path="/notre-histoire" component={Histoire}/>
      <Route path="/contact" component={Contact}/>
      <Route path="/huitres" component={Huitres}/>
      <Route path="/mentions-legales" component={MentionsLegales}/>
      <Redirect to ="/"/>
    </Switch>
    <Footer/> 
    </>
  );
}

export default App;
