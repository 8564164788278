import React,{useEffect} from 'react';
import video from './../video/labelleGood.mp4';
import videoMov from './../video/labelleGood.mov';
import videoWebm from './../video/labelleGood.webm';
import Aos from "aos";
import { NavLink } from 'react-router-dom';

const Home = () => {
    useEffect(()=>{
        Aos.init({
            duration: 1300,
            disable: 'mobile'});
    },[]);
    return (
        <div className="container-home">
            <div className="page">
             </div>
            <div className="container-video">
                <video className="videoHome" autoPlay loop muted playsInline controls>
                    <source  src={video}  type="video/mp4"/>
                    <source src={videoMov} type="video/mov"/>
                    <source src={videoWebm} type="video/webm"/>
                    Video la Belle de ronce
                </video> 
            </div>
            <div className="sections">
                <div className="paralaxe-section-1">
                    <div className="container-section-1" data-aos="fade-left">
                        <div className="btn btn-1">
                            <section className="section-1">
                                    <NavLink exact to='/notre-histoire'>
                                        <h2 className="title-section-1">L'histoire de la Maison</h2>
                                    </NavLink>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="paralaxe-section-2">
                    <div className="container-section-2" data-aos="fade-right">
                        <div className="btn btn-2">
                            <section className="section-2">
                                    <NavLink to='/huitres'>
                                        <h2 className="title-section-2">L'élévage des Huîtres</h2>
                                    </NavLink>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="paralaxe-section-3">
                    <div className="container-section-3" data-aos="fade-left">
                        <div className="btn btn-3">
                            <section className="section-3">
                                    <NavLink to='/contact'>
                                        <h2 className="title-section-3">Nous contacter</h2>
                                    </NavLink>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;