import React from 'react';
import Cards from './../components/Cards';
import imgHuitre from './../img/huitres-sel.jpg';
import imgLBDR from './../img/lbdr.jpg';
import imgLogo from './../img/logo-argent.png';

const Huitres = () => {

    return (
        //className="page-huitre"
    <div>
        <h1 className="title-content">Nos Huîtres</h1>
         <figure className="bg-huitre"></figure>
            <img className="huitre-sel" alt="decorative-img" title="decorative-image" src={imgHuitre}/>
            <div className="content-cards">
            <div className="card">
              <div className="card__inner">
                  <div className='card__face card__face--front' >
                        <img className="h_img" src={imgLBDR} alt='Huître la Belle de Ronce' title='Huître la Belle de Ronce'/>
                                <h3 className="title-huitre-lbdr">Spéciale La Belle de Ronce</h3>
                                {/* <i className="fa fa-plus"></i> */}
                            </div>
                        <div className='card__face card__face--back'>
                            <div className="card__content">
                                    <div className="labelise">
                                        <img className="img-label" src={imgLogo} alt='no-img-label' title='no-img-label'/>
                                    </div>
                                {/* <div className="card__header">
                                    <img className="huitres_img" src={img2} alt="" title=""/>
                                    <h3 className="sous_title"></h3>
                                </div> */}
                                <div className="card__body">
                                    <h5 className="des_title">Spéciale La Belle de Ronce</h5>
                                     <p className="des_huitres">La Belle de Ronce est une huître de pleine mer. Sélectionnée par nos soins, La Belle de Ronce atteint la qualité exceptionnelle d’une huître de prestige.</p>
                                </div>
                             </div>
                        </div>  
                        </div>
                    </div>
                </div>
           <Cards/>
          {/* <div className="grid-huitres">
                <div class="contain-huitres">
                    <img className="huitres" src={img2} alt="" title=""/>
                    <img className="label" src={img2} alt="" title=""/>
                    <p>Huitre desctiption : bla bla bla bla lba</p>
                </div>
                <div class="contain-huitres">
                    <img className="huitres" src={img2} alt="" title=""/>
                    <img className="label" src={img2} alt="" title=""/>
                    <p>Huitre desctiption : bla bla bla bla lba</p>
                </div>
                <div class="contain-huitres">
                    <img className="huitres" src={img2} alt="" title=""/>
                    <img className="label" src={img2} alt="" title=""/>
                    <p>Huitre desctiption : bla bla bla bla lba</p>
                </div>
            </div>
            <div className="grid-huitres">
                <div class="contain-huitres">
                    <img className="huitres" src={img2} alt="" title=""/>
                    <img className="label" src={img2} alt="" title=""/>
                    <p>Huitre desctiption : bla bla bla bla lba</p>
                </div>
                <div class="contain-huitres">
                    <img className="huitres" src={img2} alt="" title=""/>
                    <img className="label" src={img2} alt="" title=""/>
                    <p>Huitre desctiption : bla bla bla bla lba</p>
                </div>
                <div class="contain-huitres">
                    <img className="huitres" src={img2} alt="" title=""/>
                    <img className="label" src={img2} alt="" title=""/>
                    <p>Huitre desctiption : bla bla bla bla lba</p>
                </div>
            </div> */}

            {/* <video
                muted
                autoPlay 
                loop>
                    <source  src={video}  type="video/mp4"/>
            </video>   */}
          
        <h3 className="title-video">Notre production</h3>
        <div className="video-container">
            <iframe className="video_h"
            height="auto" width="100%"
                src="https://www.youtube-nocookie.com/embed/M-6cqGbumu4" title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen></iframe>
                <br/><br/>
            </div>
        </div>
    );
};

export default Huitres;