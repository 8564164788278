import React from 'react';

const SocialNetwork = () => {
    // const anim = () =>{
    //     let navLinks = document.querySelectorAll('.social-network a');
        
    //     navLinks.forEach(link =>{
    //         link.addEventListener('mouseover', (e) =>{
    //             let attrX = e.offsetX - 2;
    //             let attrY = e.offsetY - 1;

    //             link.style.transform = `translate(${attrX}px, ${attrY}px)`; 
    //         })
    //         link.addEventListener('mouseleave', () =>{
    //             link.style.transform = '';
    //         })
    //     })
    //}
    // en propriété de a => onMouseOver={anim}
    return (
        <>
        <div className="social-network">
            <h3 className="abonne">Suis Nous !</h3>
            <ul className="content-social" data-aos="zoom-in">
                <a href="https://www.facebook.com/labellederonceMB" target="_blank" rel="noopener noreferrer" className="hover" ><li><i className="fab fa-facebook-f"></i></li></a>
                {/* <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="hover"><li><i className="fab fa-instagram"></i></li></a> */}
            </ul>  
        </div>
        </>
    );
};

export default SocialNetwork;